body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.result-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other content */
  backdrop-filter: blur(5px); /* Apply blur effect to the background */
}

.result-content {
  width: 75%; /* Set width to 80% */
  height: 75%; /* Set height to 80% */
  max-width: 600px; /* Ensure the content doesn't exceed 80% width */
  max-height: 800px; /* Ensure the content doesn't exceed 80% height */
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow-y: auto; /* Enable vertical scrolling when content exceeds height */
}

.next-button {
  position: absolute;
  bottom: 10px; /* Adjust the desired distance from the bottom */
  right: 10px; /* Adjust the desired distance from the right */
}

.previous-button {
  position: absolute;
  bottom: 10px; /* Adjust the desired distance from the bottom */
  left: 10px; /* Adjust the desired distance from the left */
}

.close-button {
  position: absolute;
  bottom: 10px; /* Adjust the desired distance from the bottom */
  left: 50%; /* Position in the center horizontally */
  transform: translateX(-50%); /* Center the button horizontally */
}